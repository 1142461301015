<template>
  <div>
    <div class="index selfskill">
      <el-form :model="editTalentInfo">
        <el-form-item label="权力要求:" label-width="100px">
          <el-input
            v-model="editTalentInfo.powerDemand"
            :autosize="{ minRows: 13 }"
            type="textarea"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="说明书:" label-width="100px">
          <el-input
            v-model="editTalentInfo.instructions"
            :autosize="{ minRows: 13 }"
            type="textarea"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editTalentInfo: {
      type: Object
    },
    personType: {
      type: Number
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  methods: {
    cancelItem() {
      this.$router.go(-1);
    }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("selfskill")[0].style.height =
        document.body.clientHeight - 250 + "px";
    }
  }
};
</script>

<style lang="less" scoped>
.index {
  // width: 100%;
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>
