<template>
  <div>
    <div class="mainIndex">
      <el-card>
        <el-tabs v-model="activeName">
          <el-tab-pane label="专利基本信息" name="first">
            <basic-info
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              ref="personMessage"
            ></basic-info>
          </el-tab-pane>
          <el-tab-pane label="法律状态" name="second">
            <state
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              ref="workHistory"
            ></state>
          </el-tab-pane>
          <el-tab-pane label="详细状态" name="third">
            <detailed
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              ref="thePaper"
            ></detailed>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <div class="bottom">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getProperty, insertProperty, updateProperty } from "@/api/equity";
import BasicInfo from "./basic/BasicInfo.vue";
import State from "./basic/State.vue";
import detailed from "./basic/detailed.vue";
export default {
  name: "editTalentXS",
  components: {
    BasicInfo,
    State,
    detailed,
    // ThePaper
  },
  computed: {
    personData() {
      return Number(this.$route.query.id);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    if (this.personType) {
      this.getTalents();
    }
  },
  data() {
    return {
      activeName: "first",
      isShowInput: false,
      // 表单校验信息
      enterTalentsInfo: {
        fileName: "", //文件名
        patentType: "", //专利类型
        patentState: "", //专利状态
        patentName: "", //专利名称
        announcementId: "", //申请号
        announcementDate: "", //申请日
        announcementPublicId: "", //申请公告号
        announcementPublicDate: "", //申请公告日
        authorizationAnnouncementId: "", //授权公告号
        authorizationAnnouncementDate: "", //授权公告日
        priorityDate: "", //优先权日
        priorityId: "", //优先权号
        typeId: "", //分类号
        announcementPerson: "", //申请人（数值企业）
        inventor: "", //发明人
        address: "", //详细地址
        postcode: "", //邮编
        agency: "", //代理机构
        agent: "", //代理人
        summary: "", //摘要
        summaryImg: "", //摘要图片
      },
    };
  },
  methods: {
    // 查询个人信息
    async getTalents() {
      const params = {
        id: this.personData,
      };
      const { data: res } = await getProperty(params);
      this.enterTalentsInfo = res;
    },
    // 判断是否为字符串
    formatString(val) {
      if (!val) {
        return [];
      } else {
        return val.split(",");
      }
    },
    // 全部保存
    saveItem() {
      Object.assign(this.enterTalentsInfo, {
        Authorization: this.Authorization,
      });
      let res;
      this.$refs.personMessage.$refs.editTalentInfo.validate(async (valid) => {
        //async
        if (valid) {
          if (!this.personType) {
            const params = {
              propertyDto: this.enterTalentsInfo,
              legalStatusDtoList: this.$refs.workHistory.form2,
            };
            res = await insertProperty(params);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            }
          } else {
            res = await updateProperty(this.enterTalentsInfo);
            if (res.code === 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            }
          }
        }
      });
    },
    // 返回
    cancelItem() {
      this.$router.go(-1);
    },
    editClick() {
      this.isShowInput = true;
    },
  },
  mounted() {
    if (this.personType) {
      // document.querySelector('.el-tabs__content').style.height =
      // document.body.clientHeight - 310 + 'px'
    } else {
      document.querySelector(".el-tabs__content").style.height =
        document.body.clientHeight - 250 + "px";
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__content {
  overflow: auto;
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}
// .mainIndex{
//   height: 650px;
//   overflow: auto;
// }
</style>
