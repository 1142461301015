<template>
  <div>
    <div class="index researchProject">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加法律状态</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center'
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="legalStatusAnnouncementDate"
          label="法律状态公告日"
        ></el-table-column>
        <el-table-column prop="legalStatus" label="法律状态"></el-table-column>
        <el-table-column
          prop="legalStatusInfo"
          label="法律状态信息"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
      <!-- <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      /> -->
      <el-dialog
        title="添加法律状态"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item
            label="法律状态公告日:"
            label-width="140px"
            prop="legalStatusAnnouncementDate"
          >
            <el-date-picker
              v-model="form.legalStatusAnnouncementDate"
              type="date"
              placeholder="请选择"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="法律状态:"
            label-width="140px"
            prop="legalStatus"
          >
            <el-input
              v-model="form.legalStatus"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法律状态信息:"
            label-width="140px"
            prop="legalStatusInfo"
          >
            <el-input
              v-model="form.legalStatusInfo"
              style="width: 430px"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 20 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑负责人信息"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
      >
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item
            label="法律状态公告日:"
            label-width="140px"
            prop="legalStatusAnnouncementDate"
          >
            <el-date-picker
              v-model="editform.legalStatusAnnouncementDate"
              type="date"
              placeholder="请选择"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="法律状态:"
            label-width="140px"
            prop="legalStatus"
          >
            <el-input
              v-model="editform.legalStatus"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法律状态信息:"
            label-width="140px"
            prop="legalStatusInfo"
          >
            <el-input
              v-model="editform.legalStatusInfo"
              style="width: 430px"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 20 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ButtonTable from "@/components/ButtonTable.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getLegalStatus,
  insertStatus,
  deleteStatusById,
  updateLegalStatus
} from "@/api/equity.js";
import { pagination } from "@/mixins/pagination";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    CascaderArea,
    DicCheckboxGroup,
    DicRadioGroup
  },
  data() {
    return {
      index: 0,
      form: {
        legalStatusAnnouncementDate: "", //法律状态公告日
        legalStatus: "", //法律状态
        legalStatusInfo: "" //法律状态信息
      },
      PayTime: [],
      formRules: {
        legalStatusAnnouncementDate: [
          { required: true, message: "请输入法律状态公告日", trigger: "blur" }
        ],
        legalStatus: [
          { required: true, message: "请输入法律状态", trigger: "blur" }
        ],
        legalStatusInfo: [
          { required: true, message: "请输入法律状态信息", trigger: "blur" }
        ]
      },
      queryInfo: {
        id: "", //人才编号
        pageSize: 10,
        pageNum: 1
      },
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      editform: {
        name: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        contactInformation: "", //联系方式
        responsibilities: "" //职责范围
      }
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    firstId() {
      return Number(this.$route.query.id);
    }
  },
  props: {
    editTalentInfo: {
      type: Object
    },
    personType: {
      type: Number
    }
  },
  created() {
    if (this.personType) {
      this.search();
    }
  },
  methods: {
    // 日期时间
    GetzhifuTime() {
      if (this.PayTime != null) {
        this.form.beginTime = this.PayTime[0];
        this.form.endTime = this.PayTime[1];
        this.editform.beginTime = this.PayTime[0];
        this.editform.endTime = this.PayTime[1];
      }
    },
    searchCountry(val) {
      this.form.sponsorCountry = val;
    },
    searchProvince(val) {
      this.form.sponsorProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.form.sponsorCity = val;
      }
    },
    searchCountry1(val) {
      this.editform.sponsorCountry = val;
    },
    searchProvince1(val) {
      this.editform.sponsorProvince = val;
    },
    searchCity1(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editform.sponsorCity = val;
      }
    },
    // 清空表单
    deleteForm() {
      this.form = {
        legalStatusAnnouncementDate: "", //法律状态公告日
        legalStatus: "", //法律状态
        legalStatusInfo: "" //法律状态信息
      };
      this.editform = {
        legalStatusAnnouncementDate: "", //法律状态公告日
        legalStatus: "", //法律状态
        legalStatusInfo: "" //法律状态信息
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      const res = await getLegalStatus({ patentId: this.firstId });
      if (res.code === 200) {
        this.form2 = res.data;
        this.total = res.data.total;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      if (this.personType) {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            Object.assign(this.form, {
              Authorization: this.Authorization,
              patentId: this.firstId
            });
            const res = await insertStatus(this.form);
            if (res.code === 200) {
              this.dialogFormVisible = false;
              this.$message.success("新增成功");
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
        });
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType) {
            const deleteForm = {
              id: data.id
            };
            const res = await deleteStatusById(deleteForm);

            if (res.code == 200) {
              this.$message.success("删除成功");
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.index = index1;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization
      });
      // this.$set(this.PayTime, 0, this.editform.beginTime)
      // this.$set(this.PayTime, 1, this.editform.endTime)
      this.PayTime = [
        new Date(this.editform.beginTime),
        new Date(this.editform.endTime)
      ];
      this.dialogFormVisible1 = true;
    },
    // 修改工作经历
    editEx(formName) {
      if (this.personType) {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const res = await updateLegalStatus(this.editform);
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.deleteForm();
              this.search();
              this.dialogFormVisible1 = false;
            }
          }
        });
      } else {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            this.form2[this.index] = this.editform;
            this.dialogFormVisible1 = false;
          }
        });
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
      this.PayTime = [];
    },
    cancelItem() {
      this.$router.go(-1);
    }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("researchProject")[0].style.height =
        document.body.clientHeight - 250 + "px";
    }
  }
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 700px;
}
.researchProject {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  margin-right: 10px;
  width: 210px !important;
}
</style>
