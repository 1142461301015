<template>
  <div>
    <div class="infoOne">
      <TitleBar title="基本信息" style="margin-bottom: 20px" />
      <el-form
        label-width="150px"
        :model="editTalentInfo"
        :rules="enterRules"
        ref="editTalentInfo"
        v-if="isShow"
      >
        <el-form-item label="专利名称：" prop="patentName">
          <el-input
            v-model="editTalentInfo.patentName"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请号：" prop="announcementId">
          <el-input
            v-model="editTalentInfo.announcementId"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请日：" prop="announcementDate">
          <el-date-picker
            v-model="editTalentInfo.announcementDate"
            type="date"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
            style="width: 200px !important"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请公告号：" prop="announcementPublicId">
          <el-input
            v-model="editTalentInfo.announcementPublicId"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请公告日：" prop="announcementPublicDate">
          <el-date-picker
            v-model="editTalentInfo.announcementPublicDate"
            type="date"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
            style="width: 200px !important"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="授权公告号：" prop="authorizationAnnouncementId">
          <el-input
            v-model="editTalentInfo.authorizationAnnouncementId"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="授权公告日：" prop="authorizationAnnouncementDate">
          <el-date-picker
            v-model="editTalentInfo.authorizationAnnouncementDate"
            type="date"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
            style="width: 200px !important"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="优先权号：" prop="priorityId">
          <el-input
            v-model="editTalentInfo.priorityId"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先权日：" prop="priorityDate">
          <el-date-picker
            v-model="editTalentInfo.priorityDate"
            type="date"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
            style="width: 200px !important"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="分类号：" prop="typeId">
          <el-input
            v-model="editTalentInfo.typeId"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传文件：">
          <div class="policyData">
            <el-input v-model="editTalentInfo.fileName" class="lager-input" />
            <upload-file
              @upFileKey="fileKey"
              @fileSucee="successUpload"
              style="margin-left: 4px"
            />
          </div>
        </el-form-item>
        <el-form-item label="专利类型：">
          <dic-radio-group
            :code.sync="editTalentInfo.patentType"
            type-code="036"
          />
        </el-form-item>
        <el-form-item label="专利状态：">
          <dic-radio-group
            :code.sync="editTalentInfo.patentState"
            type-code="037"
          />
        </el-form-item>
        <TitleBar title="申请人信息" style="margin-bottom: 20px" />
        <el-form-item label="申请（专利权）人：" prop="announcementPerson">
          <!-- <el-input
            v-model="editTalentInfo.announcementPerson"
            style="width:470px"
          ></el-input> -->
          <el-select
            v-model="editTalentInfo.announcementPerson"
            filterable
            allow-create
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 470px"
            :clearable="true"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.companyFullName"
              :value="item.companyCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发明人：" prop="inventor">
          <el-input
            v-model="editTalentInfo.inventor"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input
            v-model="editTalentInfo.address"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮编：" prop="postcode">
          <el-input
            v-model="editTalentInfo.postcode"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <TitleBar title="代理人信息" style="margin-bottom: 20px" />
        <el-form-item label="代理机构：" prop="agency">
          <el-input
            v-model="editTalentInfo.agency"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理人：" prop="agent">
          <el-input
            v-model="editTalentInfo.agent"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <TitleBar title="摘要" style="margin-bottom: 20px" />
        <el-form-item label="摘要：" prop="summary">
          <el-input
            v-model="editTalentInfo.summary"
            style="width: 90% !important"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="摘要图片：" prop="summary">
          <upload-image
            :imageUrl.sync="editTalentInfo.summaryImgUrl"
            :saveUrl.sync="editTalentInfo.summaryImg"
          />
        </el-form-item>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">
          创建人：{{ editTalentInfo.createName }}
        </div>
        <div class="ess_people_list">
          修改人：{{ editTalentInfo.updateName }}
        </div>
        <div class="ess_people_list">
          创建时间：{{ editTalentInfo.createTime }}
        </div>
        <div class="ess_people_list">
          修改时间：{{ editTalentInfo.updateTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";
import uploadFile from "@/views/PolicyData/uploadFile";
import TitleBar from "@/components/TitleBar.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import { findCompany } from "@/api/caseData";
export default {
  components: {
    TitleBar,
    UploadImage,
    uploadFile,
    DicRadioGroup,
  },
  created() {
    setTimeout(() => {
      this.isShow = true;
      if (this.personType) {
        this.options = [
          {
            companyFullName: this.editTalentInfo.companyName,
            companyCode: this.editTalentInfo.announcementPerson,
          },
        ];
      }
    }, 1000);
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personType: {
      type: Number,
    },
  },
  data() {
    return {
      options: [],
      options1: [],
      loading: false,
      loading1: false,
      isShow: false,
      enterRules: {
        patentName: [
          { required: true, message: "请输入专利名称", trigger: "blur" },
        ],
        announcementPerson: [
          { required: true, message: "请输入申请人", trigger: "blur" },
        ],
        announcementId: [
          { required: true, message: "请输入申请号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //文件上传返回的参数
    fileKey(val) {
      this.editTalentInfo.file = val;
    },
    //文件上传成功返回的名字
    successUpload(val) {
      this.editTalentInfo.fileName = val;
    },
    //上传组件图片地址
    clickOldTu(val) {
      this.editTalentInfo.productImg = val;
    },
    clickTu(val) {
      this.editTalentInfo.productImgs = val;
    },
    clickOldTu1(val) {
      this.editTalentInfo.frameworkImg = val;
    },
    clickTu1(val) {
      this.editTalentInfo.frameworkImgs = val;
    },
    clickOldTu2(val) {
      this.editTalentInfo.sceneApplicationImg = val;
    },
    clickTu2(val) {
      this.editTalentInfo.sceneApplicationImgs = val;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const companyName = query;
          const res = await findCompany({ companyName: companyName });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("infoOne")[0].style.height =
        document.body.clientHeight - 250 + "px";
    }
  },
};
</script>

<style lang="less" scoped>
.policyData {
  display: flex;
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}

/deep/.el-input {
  width: 470px !important;
}
.infoOne {
  // height: 600px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.picture {
  position: relative;
  p {
    position: absolute;
    right: 20%;
  }
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
</style>
